import {
    OrgListedObject,
    useDeleteObjectsByIdMutation,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { AlertDialog } from '@local/web-design-system/dist/components/Dialog/AlertDialog';
import { EllipsisMenu } from '@local/web-design-system/dist/components/EllipsisMenu';
import { ListItem } from '@local/web-design-system/dist/components/GenericListing';
import { MenuItemConfig } from '@local/web-design-system/dist/components/Menu';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { MoveToBinIcon } from '@local/web-design-system/dist/icons';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';
import {
    RECYCLE_DIALOG_CANCEL,
    RECYCLE_DIALOG_CONFIRM,
    RECYCLE_DIALOG_CONTENT,
    RECYCLE_DIALOG_TITLE,
    RECYCLE_DIALOG_WARNING,
    RECYCLE_OBJECT_ACTION,
    RECYCLE_TOAST_FAILURE,
    RECYCLE_TOAST_SUCCESS,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

import { objectDefinition } from './FieldRowDefinitions';
import { ListedObjectDisplay } from './utils';

function RecycleAction() {
    return (
        <Grid
            gap={2}
            container
            alignItems="center"
            justifyContent="flex-start"
            automation-id="object-row-delete-action"
        >
            <MoveToBinIcon />
            <Typography>{RECYCLE_OBJECT_ACTION}</Typography>
        </Grid>
    );
}

interface ObjectActionProps {
    onRecycle: () => void;
}

function ObjectActions({ onRecycle }: ObjectActionProps) {
    const menuOptions: MenuItemConfig[] = [
        {
            key: 'recycle',
            action: onRecycle,
            ItemComponent: RecycleAction,
        },
    ];

    return (
        <EllipsisMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            menuOptions={menuOptions}
        />
    );
}

interface ObjectRowProps {
    refetch: () => void;
    object: ListedObjectDisplay | OrgListedObject;
    displayEmptyActions?: boolean;
}

export function ObjectRow({ object, refetch, displayEmptyActions = false }: ObjectRowProps) {
    const params = useParams();
    const { addMessage } = useMessagesContext();
    const { evouiEnableDelete } = useFlags();
    const [id, setID] = usePersistedState('id');
    const [recycleOpen, setRecycleOpen] = useState(false);
    const [recycleObject] = useDeleteObjectsByIdMutation();

    const isSelected = id === object?.object_id;

    const handleRowClick = () => {
        if (isSelected) {
            setID(undefined);
        } else {
            setID(object?.object_id);
        }
    };

    const onRecycle = (confirm: boolean | null) => {
        if (confirm) {
            recycleObject({
                objectId: object.object_id,
                orgId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
            })
                .then((response: any) => {
                    if (response.error) {
                        addMessage({
                            message: RECYCLE_TOAST_FAILURE,
                            type: NotificationType.ERROR,
                        });
                    } else {
                        addMessage({
                            message: RECYCLE_TOAST_SUCCESS,
                            type: NotificationType.SUCCESS,
                        });
                        refetch();
                    }
                })
                .catch(() => {
                    addMessage({
                        message: RECYCLE_TOAST_FAILURE,
                        type: NotificationType.ERROR,
                    });
                });
        }
        setRecycleOpen(false);
    };

    return (
        <>
            <ListItem
                item={object}
                selected={isSelected}
                fields={objectDefinition}
                clickHandler={handleRowClick}
                displayEmptyActions={displayEmptyActions}
                actions={
                    evouiEnableDelete && <ObjectActions onRecycle={() => setRecycleOpen(true)} />
                }
            />
            <AlertDialog
                open={recycleOpen}
                icon={<MoveToBinIcon />}
                action={onRecycle}
                cancelText={RECYCLE_DIALOG_CANCEL}
                confirmText={RECYCLE_DIALOG_CONFIRM}
                dialogTitle={RECYCLE_DIALOG_TITLE}
                dialogContent={
                    <div>
                        {`${RECYCLE_DIALOG_CONTENT} "${formatObjectName(object.name)}".`}
                        <br />
                        {RECYCLE_DIALOG_WARNING}
                    </div>
                }
            />
        </>
    );
}
