import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import { loadedObjectsMap, getTreeItemById } from 'src/store/visualization/selectors';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { useObjectLoader } from 'src/visualization/context/hooks/useObjectLoader/useObjectLoader';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId: string) {
    const dispatch = useAppDispatch();

    const treeItem = useAppSelector(getTreeItemById(listItemId));
    function loadObject() {
        if (isObjectLoaded(listItemId)) {
            if (isError) {
                dispatch(removeFromLoadedObjects(listItemId));

                const viewId = getViewIdFromObjectId(listItemId, treeItem.schema);
                disposeEntity(viewId);
            } else {
                return;
            }
        }
        setIsError(false);
        setIsLoading(true);
        setTimeout(() => dispatch(addToLoadedObjects(treeItem)));
    }

    const loadedObjects = useAppSelector(loadedObjectsMap);
    function isObjectLoaded(objectId: string) {
        return objectId in loadedObjects;
    }

    const { isLoading: isNetworkLoading, isError: isNetworkError } = useObjectLoader(
        treeItem,
        false,
    );
    const [isLoading, setIsLoading] = useState(isNetworkLoading);
    const [isError, setIsError] = useState(isNetworkLoading);
    useEffect(() => {
        if (isNetworkLoading) {
            setIsLoading(true);
            setIsError(false);
        } else if (isNetworkError) {
            setIsLoading(false);
            setIsError(true);
        } else if (!isObjectLoaded(listItemId)) {
            setIsLoading(false);
            setIsError(false);
        }
    }, [isNetworkLoading, isNetworkError, loadedObjects]);

    const { useXyzListener, addViewStatusListener, disposeEntity } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });
    useEffect(() => {
        const objectViewId = views.find((view) => view.startsWith(listItemId));
        if (!objectViewId) return () => {};

        const removeViewStatusListener = addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                setIsLoading(false);
                setIsError(false);
                removeViewStatusListener();
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                setIsError(true);
            },
            onPending: () => {
                setIsLoading(true);
                setIsError(false);
            },
        });
        return () => {
            removeViewStatusListener();
        };
    }, [views]);

    return {
        loadObject,
        isError,
        isLoading,
        isObjectLoaded,
    };
}
