import { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { toSuffixUid } from '@local/webviz/dist/xyz';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import capitalize from 'lodash-es/capitalize';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect } from 'react';

import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import { getAllLoadedDownholeCollections } from 'src/store/goose/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { addTreeItemChildren, updateTree } from 'src/store/visualization/visualizationSlice';
import { FolderStructure, TreeItemType } from 'src/store/visualization/visualizationSlice.types';
import { ROOT_TREE_ID } from 'src/strings';
import { Schemas } from 'src/visualization/constants';
import { useQueue } from 'src/visualization/context/hooks/useQueue/useQueue';
import type { DownholeCollectionType } from 'src/visualization/types';

import {
    CONCURRENCE_VALUE,
    INTERVAL,
    POINTSET,
    TREE_BATCH_SIZE,
} from '../../ObjectsPanel/ProjectTree/BuildObjectTree.constants';
import { FoldersProps } from '../../ObjectsPanel/ProjectTree/ProjectTreePanel.types';

export function useBuildObjectTree(objects: ListedObject[]) {
    let treeStateUpdate: { [key: string]: TreeItemType } = {};

    const dispatch = useAppDispatch();
    const { enqueue } = useQueue(CONCURRENCE_VALUE);

    function flushDictionary() {
        dispatch(
            updateTree({
                treeDictionary: { ...treeStateUpdate },
            }),
        );
        clearDictionary();
    }

    function addDictionaryItem(treeItem: TreeItemType) {
        if (Object.keys(treeStateUpdate).length > TREE_BATCH_SIZE) {
            flushDictionary();
        }
        const { treeId } = treeItem;
        treeStateUpdate[treeId] = { ...treeItem, children: [...treeItem.children] };
    }

    function clearDictionary() {
        treeStateUpdate = {};
    }

    useEffect(() => {
        if (objects.length) {
            clearDictionary();
            const allObjectIds = objects.map((object) => object.object_id);
            addTopLevelFolders(allObjectIds);
            addChildrenForTopLevelFolders(objects);
            flushDictionary();
        }
    }, [objects]);

    const downholeCollections = useAppSelector(getAllLoadedDownholeCollections);
    useEffect(() => {
        if (objects.length) {
            addCollectionsForProjectTree(downholeCollections, addDictionaryItem, dispatch);
            flushDictionary();
        }
    }, [downholeCollections]);

    function addChildrenForTopLevelFolders(objectList: ListedObject[]) {
        const folders: FoldersProps = {};
        objectList.forEach(({ object_id, name, schema }) => {
            if (schema.includes(Schemas.DownholeCollectionSchema)) {
                addDictionaryItem({
                    treeId: toSuffixUid(object_id, POINTSET),
                    children: [],
                    name: `${fileNameExtensionRemover(name)} ${capitalize(POINTSET)}`,
                    parentId: object_id,
                    schema: Schemas.PointsetSchema,
                });
                // for now only collections are folders
                const folder: FolderStructure = {
                    treeId: object_id,
                    children: [],
                    name: fileNameExtensionRemover(name),
                    isFolder: true,
                    parentId: ROOT_TREE_ID,
                    schema: Schemas.DownholeCollectionSchema,
                };

                folders[object_id] = folder;
                return;
            }

            addDictionaryItem({
                treeId: object_id,
                children: [],
                name: fileNameExtensionRemover(name),
                parentId: ROOT_TREE_ID,
                schema: schema as Schemas,
            });
        });
        if (!isEmpty(folders)) {
            // create folders for collections
            addFolderStructureAndPointsToTree(ROOT_TREE_ID, folders, addDictionaryItem);
        }
        const objectsToLoad = Object.keys(folders);
        enqueue(objectsToLoad);
    }

    function addTopLevelFolders(rootLevelChildren: string[]) {
        addDictionaryItem({
            treeId: ROOT_TREE_ID,
            children: rootLevelChildren,
            name: ROOT_TREE_ID,
            isFolder: true,
            parentId: '',
        } as FolderStructure);
    }
}

type AddDictionaryItemType = (treeItem: TreeItemType) => void;

function addFolderStructureAndPointsToTree(
    parentId: string,
    folders: FoldersProps,
    addDictionaryItem: AddDictionaryItemType,
) {
    Object.values(folders).forEach((item) => {
        addDictionaryItem({
            treeId: item.treeId,
            children: [toSuffixUid(item.treeId, POINTSET)],
            name: item.name,
            isFolder: true,
            parentId,
            schema: item.schema,
        });
    });
}

function addCollectionsForProjectTree(
    collections: [string, DownholeCollectionType[]][],
    addDictionaryItem: AddDictionaryItemType,
    dispatch: Dispatch<AnyAction>,
) {
    collections.forEach(([treeId, collectionsList]) => {
        const collectionChildrenIds: string[] = [];
        collectionsList.forEach(({ name, collection_type }) => {
            const isCollectionInterval = collection_type === INTERVAL;
            if (isCollectionInterval) {
                addDictionaryItem({
                    treeId: toSuffixUid(treeId, name),
                    children: [],
                    name,
                    parentId: treeId,
                    schema: Schemas.DownholeIntervalsSchema,
                });
            }
            collectionChildrenIds.push(toSuffixUid(treeId, name));
        });
        // update children of the collection
        dispatch(addTreeItemChildren({ treeId, children: collectionChildrenIds }));
    });
}
