import { FolderIcon } from '@local/web-design-system/dist/icons/Files/FolderIcon';
import { ListViewIcon } from '@local/web-design-system/dist/icons/Toggles/ListViewIcon';
import { useListUserRolesQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import { WorkspaceSearch } from '@local/workspaces/src/pages/workspaceListingPage/WorkspaceSearch';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { filterTypeMetaData } from 'src/mockApi/mockFilterDataTypes';
import { AffinityFolderViews } from 'src/pages/workspacePage/affinityFoldersContent/AffinityFolderViews';
import {
    setWorkspaceContentMode,
    workspaceContentModeSelector,
} from 'src/store/features/displaySettingSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { ENTER_OBJECT_NAME } from 'src/strings';

import { useStyles } from './ContentOptions.styles';
import { ObjectFilterMenu } from './objectFilterMenu/ObjectFilterMenu';
import { ObjectFilterPills } from './objectFilterMenu/ObjectFilterPills';

const ViewModeButtons = () => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const { evouiAffinityFolders } = useFlags();

    return (
        <Grid className={classes.viewModeButtons}>
            <IconButton
                onClick={() => {
                    dispatch(setWorkspaceContentMode('path'));
                }}
                size="large"
                className={classNames({
                    [classes.viewButton]: true,
                    [classes.currentViewButton]: workspaceContentMode === 'path',
                })}
                automation-id="path-view"
            >
                <ListViewIcon fontSize="small" color="inherit" />
            </IconButton>
            {evouiAffinityFolders && (
                <IconButton
                    onClick={() => {
                        dispatch(setWorkspaceContentMode('affinity-folders'));
                    }}
                    size="large"
                    className={classNames({
                        [classes.viewButton]: true,
                        [classes.currentViewButton]: workspaceContentMode === 'affinity-folders',
                    })}
                    automation-id="affinity-folder-view"
                >
                    <FolderIcon fontSize="small" color="inherit" />
                </IconButton>
            )}
        </Grid>
    );
};

export const ContentOptions = ({ paginationControls }: { paginationControls?: ReactNode }) => {
    const { classes } = useStyles();
    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);

    const showFilterDropdown = evouiAffinityFolders && workspaceContentMode === 'path';

    const params = useParams();

    const userListResponse = useListUserRolesQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        hubUrl: getHubUrlForCurrentOrg(),
    });
    const { data, isLoading } = useMemo(
        () =>
            showFilterDropdown
                ? userListResponse
                : { data: { results: undefined }, isLoading: false },
        [showFilterDropdown, userListResponse],
    );
    const userData = data?.results || [];

    const { search, setSearch } = useObjectSearchParams();

    return (
        <>
            <Grid className={classes.optionsContainer}>
                <ViewModeButtons />
                {paginationControls}
                {evouiAffinityFolders && workspaceContentMode === 'affinity-folders' && (
                    <AffinityFolderViews />
                )}
                <Grid item xs container justifyContent="right" alignItems="center">
                    {showFilterDropdown && (
                        <Grid
                            container
                            alignItems="center"
                            flexWrap="nowrap"
                            className={classes.searchFilterContainer}
                        >
                            <ObjectFilterMenu
                                userData={userData}
                                isLoadingUsers={isLoading}
                                typeData={filterTypeMetaData.options}
                            />
                            <WorkspaceSearch
                                setSearchTerm={setSearch}
                                searchPlaceHolderValue={ENTER_OBJECT_NAME}
                                userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                                userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                                defaultValue={search}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {showFilterDropdown && <ObjectFilterPills userData={userData} isLoading={isLoading} />}
        </>
    );
};
