import {
    OrgListedObject,
    useListObjectsByOrgQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { TitleRow } from '@local/content-area';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import { SortedList } from '@local/web-design-system';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import {
    FieldDefinition,
    Order,
    ListItem,
} from '@local/web-design-system/dist/components/GenericListing';
import { BlankTableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton/BlankTableLoadingSkeleton';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';

import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_CONTENT,
    RECYCLE_BIN_NO_CONTENT_DESC,
    RECYCLE_BIN_TITLE,
} from 'src/strings';
import { recycleBinBreadcrumb } from 'src/utils/breadcrumbs';

import { objectDefinition } from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from './RecycleBinPage.styles';

interface OrgObjectListProps {
    objects: OrgListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition<any>[];
}

export const OrgObjectList = ({
    objects,
    isLoading,
    handleSort,
    fields = objectDefinition,
}: OrgObjectListProps) => {
    const { classes } = useStyles();
    if (isLoading) {
        return <BlankTableLoadingSkeleton />;
    }

    if (!isLoading && !objects.length) {
        <Grid container flexGrow={1} alignItems="center" justifyContent="center">
            <EmptyState
                title={RECYCLE_BIN_NO_CONTENT}
                message={RECYCLE_BIN_NO_CONTENT_DESC}
                image={
                    <div className={classes.noObjectsImage}>
                        <RecycleBinEmptySvg />
                    </div>
                }
                classes={{ root: classes.noObjects }}
            />
        </Grid>;
    }

    return (
        <SortedList
            itemDef={fields}
            stickyHeader
            isEmpty={!objects.length}
            classes={classes}
            onHeaderSortClickedEventHandler={handleSort}
            hasActions
        >
            {objects.map((object: OrgListedObject) => (
                <ListItem
                    key={object.object_id}
                    item={object}
                    fields={fields}
                    displayEmptyActions
                />
            ))}
        </SortedList>
    );
};

export function RecycleBinPage() {
    const { classes } = useStyles();
    const params = useParams();

    setDocumentTitle(RECYCLE_BIN_TITLE);

    const segments: BreadcrumbSegment[] = [recycleBinBreadcrumb];

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsByOrgQuery({
        orgId: getOrgUuidFromParams(params),
        deleted: true,
    });

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <Grid container wrap="nowrap" direction="column">
            <Breadcrumb segments={segments} medium />
            <TitleRow title={RECYCLE_BIN_TITLE} />
            <Grid container direction="column" gap={1} className={classes.hoveringTable}>
                <OrgObjectList
                    objects={gooseData?.objects ?? []}
                    isLoading={isFetching}
                    handleSort={() => {}}
                />
            </Grid>
        </Grid>
    );
}
