import { MultiSelectDragImage } from '@local/web-design-system/dist/icons/MultiSelectDragImage';
import { handleMultiSelect } from '@local/webviz/dist/utilities';
import React, { useCallback, useContext } from 'react';

import {
    isSelectedInProjectTree,
    lastSelectedProjectTree,
    selectionListProjectTree,
    shiftSelectionProjectTree,
} from 'src/store/visualization/selectors';
import {
    multiSelectObjectProjectTree,
    selectObjectProjectTree,
    unselectObjectProjectTree,
} from 'src/store/visualization/visualizationSlice';
import { SelectObject } from 'src/store/visualization/visualizationSlice.types';

import { store, useAppDispatch } from '../../../store/store';
import { GooseListContext } from '../gooseContext/gooseContext';

const OBJECT_PANEL = 'text/object-id';

export function useDrag(objectId: string) {
    const dispatch = useAppDispatch();
    const { data } = useContext(GooseListContext);
    const treeListItems = data.objects.map((object) => object.object_id);

    const onClick = (event: React.MouseEvent) => {
        const lastSelected = lastSelectedProjectTree(store.getState());
        const selected = isSelectedInProjectTree(objectId)(store.getState());

        if (lastSelected && event.shiftKey) {
            // unselect the current shift selection
            const shiftSelection = shiftSelectionProjectTree(store.getState());
            shiftSelection.forEach((id: string) => {
                dispatch(
                    unselectObjectProjectTree({
                        objectId: id,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            });

            const index1 = treeListItems.indexOf(lastSelected);
            const index2 = treeListItems.indexOf(objectId);
            const [first, last] = index1 < index2 ? [index1, index2] : [index2, index1];
            for (let step = first; step <= last; step += 1) {
                dispatch(
                    multiSelectObjectProjectTree({
                        objectId: treeListItems[step],
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            }
        } else {
            handleMultiSelect({
                event: event as React.MouseEvent | React.DragEvent,
                selected,
                onMultiSelect: () => {
                    dispatch(
                        multiSelectObjectProjectTree({
                            objectId,
                            shift: event.shiftKey,
                        } as SelectObject),
                    );
                },
                onUnselect: () =>
                    dispatch(
                        unselectObjectProjectTree({
                            objectId,
                            shift: event.shiftKey,
                        } as SelectObject),
                    ),
                onSelect: () => {
                    dispatch(
                        selectObjectProjectTree({
                            objectId,
                            shift: event.shiftKey,
                        } as SelectObject),
                    );
                },
            });
        }
    };

    const onDragStart = useCallback(
        (event: React.DragEvent) => {
            const selectedObjects = selectionListProjectTree(store.getState());
            event.dataTransfer.clearData();
            if (selectedObjects.length > 1 && selectedObjects.includes(objectId)) {
                event.dataTransfer.setData(OBJECT_PANEL, JSON.stringify(selectedObjects));
                event.dataTransfer.setDragImage(multiDragImage, 0, 0);
            } else {
                event.dataTransfer.setData(OBJECT_PANEL, JSON.stringify([objectId]));
                event.dataTransfer.setDragImage(event.currentTarget, 0, 0);
            }
        },
        [objectId],
    );

    /**
     * Selects the entire list of objects when only one object is double-clicked.
     */
    const onDoubleClick = useCallback(() => {
        treeListItems.forEach((id) => {
            dispatch(
                multiSelectObjectProjectTree({
                    objectId: id,
                } as SelectObject),
            );
        });
    }, [objectId]);

    return {
        onClick,
        onDoubleClick,
        onDragStart,
    };
}

export function useDrop() {
    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // Fixed on a separate ticket
    };

    return { onDrop };
}

export function onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy';
}

/**
 * Drag Images must exist on a visible node in the DOM
 * We append and then remove the image on the drag start/stop
 * event handlers
 *
 * Otherwise Safari will not apply the drag image and the drag event fails
 * https://www.kryogenix.org/code/browser/custom-drag-image.html
 */
const size = 32;
const icon = `data:image/svg+xml;base64,${btoa(MultiSelectDragImage({ size }))}`;
const multiDragImage = new Image();
multiDragImage.src = icon;
multiDragImage.style.position = 'absolute';
multiDragImage.style.top = `-${size}px`;
multiDragImage.style.left = `-${size}px`;
document.body.appendChild(multiDragImage);
