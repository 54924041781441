import {
    DockableContainer,
    DockPosition,
} from '@local/web-design-system/dist/components/DockableContainer';
import { PropertiesHeader } from '@local/web-design-system/dist/components/PropertiesHeader';
import { MeasurementDialog } from '@local/webviz/dist/components/Measurement';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import type { AttributeProps } from '@local/webviz/dist/components/Properties/AttributesControl';
import {
    PROPERTIES_TITLE,
    PropertiesDialog,
} from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { SelectionContents, SelectionPanel } from '@local/webviz/dist/components/Selection';
import { Settings, SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import {
    SLICE,
    DockableSliceDialog as SliceDialog,
} from '@local/webviz/dist/components/Slice/SliceDialog';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { getGooseObjectById } from 'src/store/goose/selectors';
import { store, useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getLoadedObjectNameByObjectId,
    getLoadedObjectSchemaByObjectId,
    loadedObjectById,
    orientationVisible,
    scalebarVisible,
    selectionListScenePanel,
    getTreeItemById,
} from 'src/store/visualization/selectors';
import { updateOverlays } from 'src/store/visualization/visualizationSlice';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';
import type { DownholeCollectionType } from 'src/visualization/types';

import {
    getObjectIdFromViewId,
    getViewIdFromObjectId,
} from '../context/snapshots/generateSnapshot';
import { DockableDialogProps, DockedWindows, ObjectAttributeType } from './DockableDialogs.types';

const DOCK_THRESHOLD = 25;
export function DockableSliceDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <SliceDialog
            header={{ title: SLICE, onClose }}
            type={DockedWindows.Slice}
            dockPosition={DockPosition.UPPER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        />
    );
}

export function DockableMeasureDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <DockableContainer
            header={{ title: MEASUREMENT_TITLE, onClose }}
            type={DockedWindows.Measure}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <MeasurementDialog />
        </DockableContainer>
    );
}

export function DockableSettingsDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const isOrientationChecked = useAppSelector(orientationVisible);
    const isScalebarChecked = useAppSelector(scalebarVisible);
    const dispatch = useAppDispatch();
    const updateOverlaysDispatch = (overlays: Partial<SettingsVisualizationOverlays>) =>
        dispatch(updateOverlays(overlays));
    const { getEntityState } = useBaseXyz();
    const plotState = getEntityState('plot');
    let numberOfObjects = 0;
    if (plotState && 'views' in plotState) {
        numberOfObjects = plotState.views.length;
    }

    return (
        <DockableContainer
            type={DockedWindows.Settings}
            header={{ title: SETTINGS_TITLE, onClose }}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <Settings
                isOrientationChecked={isOrientationChecked}
                isScalebarChecked={isScalebarChecked}
                numberOfObjects={numberOfObjects}
                updateOverlays={updateOverlaysDispatch}
            />
        </DockableContainer>
    );
}

export function DockablePropertiesDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    let attributesDict: AttributeProps = {};
    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const firstObject = useAppSelector(getGooseObjectById(selectedObjectIds[0]));
    const firstTreeItem = useAppSelector(getTreeItemById(selectedObjectIds[0]));
    const parentObject = useAppSelector(getGooseObjectById(firstTreeItem.parentId));
    const parentTreeItem = useAppSelector(getTreeItemById(firstTreeItem.parentId));
    switch (parentTreeItem.schema) {
        case Schemas.DownholeCollectionSchema: {
            switch (firstTreeItem.schema) {
                case Schemas.DownholeIntervalsSchema: {
                    const { collections } = parentObject.object as DownholeCollectionType;
                    const selectedCollection = collections.find(
                        (collection) => collection.name === firstTreeItem.name,
                    );
                    const selectedObjectAttributes =
                        selectedCollection?.from_to?.attributes?.reduce(
                            (
                                accumulator: ObjectAttributeType,
                                { name, values }: ObjectAttributeType,
                            ) => ({
                                ...accumulator,
                                [name]: { propertyId: `attribute_${values.data}` },
                            }),
                            {},
                        );
                    attributesDict = selectedObjectAttributes;
                    break;
                }
                default: {
                    attributesDict = {};
                }
            }
            break;
        }
        default: {
            const selectedObjectAttributes = firstObject?.object.attributes?.reduce(
                (accumulator: ObjectAttributeType, { name, values }: ObjectAttributeType) => ({
                    ...accumulator,
                    [name]: { propertyId: `attribute_${values.data}` },
                }),
                {},
            );
            attributesDict = selectedObjectAttributes;
        }
    }

    const selectedViewIds = Object.values(selectedObjectIds).map((objectId) => {
        const schema = getLoadedObjectSchemaByObjectId(objectId)(store.getState());
        return getViewIdFromObjectId(objectId, schema ?? '');
    });
    const multipleSelected = selectedViewIds.length > 1;
    const showHeaderAdornment = !multipleSelected && firstTreeItem.name;

    return (
        <DockableContainer
            header={{
                title: PROPERTIES_TITLE,
                headerAdornment: showHeaderAdornment && (
                    <PropertiesHeader
                        name={firstTreeItem.name}
                        Icon={SchemaIcon(extractSchema(firstTreeItem.schema))}
                    />
                ),
                onClose,
            }}
            type={DockedWindows.Properties}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <PropertiesDialog
                selectedViewIds={selectedViewIds}
                attributes={attributesDict}
                showEmptyMessage={!firstTreeItem.name}
            />
        </DockableContainer>
    );
}

export function DockableSelectionPanel() {
    const { getEntityState } = useBaseXyz();
    const { selectionState } = useSelection();

    const viewId = selectionState?.viewId ?? '';
    const viewState = getEntityState(viewId);

    const objectId = getObjectIdFromViewId(viewId);
    const selectedObject = useAppSelector(loadedObjectById(objectId));
    const selectedObjName = useAppSelector(getLoadedObjectNameByObjectId(objectId));
    const selectedObjSchema = useAppSelector(getLoadedObjectSchemaByObjectId(objectId));

    if (!selectionState?.position || !viewState || !selectedObject) {
        return null;
    }

    const Icon = selectedObjSchema ? SchemaIcon(extractSchema(selectedObject.schema)) : undefined;

    const stylePosition = {
        bottom: 16,
        left: 8,
    };

    // TODO add selection contents when ready
    return (
        <DockableContainer type={DockedWindows.SelectionPanel} stylePosition={stylePosition}>
            <SelectionPanel title={selectedObjName ?? ''} Icon={Icon}>
                <SelectionContents selectionKeyValues={[]} />
            </SelectionPanel>
        </DockableContainer>
    );
}
