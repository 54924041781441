import { createSelector } from '@reduxjs/toolkit';

import { Schemas } from 'src/visualization/constants';
import type { DownholeCollectionType } from 'src/visualization/types';

import { RootState } from '../store';
import type {
    SelectorTypeStringDownholeCollectionArray,
    SelectorTypeGooseObject,
} from '../visualization/visualizationSlice.types';

const dataKey = 'data';
const getObjectByIdKey = 'getObjectById';

const gooseState = (state: RootState) => state.goose;

export const getAllLoadedDownholeCollections: SelectorTypeStringDownholeCollectionArray =
    createSelector(gooseState, (stateRoot) => {
        const downholeCollections: [string, DownholeCollectionType[]][] = [];
        Object.entries(stateRoot.queries).forEach(([queryId, queryValue]: [string, any]) => {
            if (queryId.includes(getObjectByIdKey)) {
                const data = queryValue[dataKey];
                if (data && data.object.schema.includes(Schemas.DownholeCollectionSchema)) {
                    downholeCollections.push([data.object.uuid, data.object.collections]);
                }
            }
        });
        return downholeCollections;
    });

export const getGooseObjectById = (objectId: string): SelectorTypeGooseObject =>
    createSelector(gooseState, (stateRoot) => {
        const gooseObject = (
            Object.entries(stateRoot.queries).find(([queryId, queryValue]: [string, any]) => {
                const isValidQueryId = queryId.includes(getObjectByIdKey);
                const isValidQueryValue = queryValue[dataKey]?.object?.uuid === objectId;
                return isValidQueryId && isValidQueryValue;
            })?.[1] as any
        )?.[dataKey];

        return gooseObject;
    });
