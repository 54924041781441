import {
    GetObjectByIdApiArg,
    GetObjectResponse,
    NamedDataDownloadUrl,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { getUrlConfig } from '@local/api-clients/dist/utils/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    LINE_DEFAULT_MODE,
    LINE_DEFAULT_WIDTH,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
    TUBE_DEFAULT_RADIUS,
    MESH_DEFAULT_COLOR,
    MESH_DEFAULT_COLOR_BACK,
} from '@local/webviz/dist/context/snapshots/defaults';
import { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { LinesMode, ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type {
    TreeStructure,
    DownholeCollectionTileset,
} from 'src/store/visualization/visualizationSlice.types';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

import { GeoscienceLineObject, GeoscienceMeshObject, DownholeCollectionType } from '../../types';
import { generateAttributeListSnapshot } from './attributeSnapshot';
import { CreateViewSnapshot, PointSnapshotParams } from './generateSnapshot.types';

export function getViewIdFromObjectId(objectId: string, fullSchema: string) {
    const extractedSchema = extractSchema(fullSchema);
    switch (extractedSchema) {
        case Schemas.PointsetSchema:
            return toSuffixUid(objectId, ViewClass.Points);
        case Schemas.DownholeIntervalsSchema:
            return toSuffixUid(objectId, ViewClass.Lines);
        case Schemas.DownholeCollectionSchema:
            return toSuffixUid(objectId, ViewClass.Points);
        case Schemas.TriangleMeshSchema:
            return toSuffixUid(objectId, ViewClass.Surface);
        default:
            return '';
    }
}

export function getObjectIdFromViewId(viewId: string) {
    const prefix = viewId.split(':')[0];
    return prefix;
}

export function createViewSnapshot(
    gooseObject: GetObjectResponse,
    params?: GetObjectByIdApiArg,
): CreateViewSnapshot {
    let viewId = '';
    const viewSnapshot = generateSnapshot();
    if (!viewSnapshot) {
        console.error(`Unsupported schema: ${gooseObject.object.schema}`);
    }

    function generateSnapshot() {
        const schema = extractSchema(gooseObject.object.schema);
        const objectId = gooseObject.object_id;
        switch (schema) {
            case Schemas.PointsetSchema: {
                viewId = toSuffixUid(objectId, ViewClass.Points);
                const snapshotParams: PointSnapshotParams = {
                    objectId: gooseObject.object_id,
                    width: gooseObject.object.locations.coordinates.width,
                    length: gooseObject.object.locations.coordinates.length,
                    dataType: gooseObject.object.locations.coordinates.data_type,
                    dataId: gooseObject.object.locations.coordinates.data,
                    data: gooseObject.links.data,
                };
                const pointsSnapshot = generatePointsSnapshot(viewId, snapshotParams);
                return pointsSnapshot;
            }
            case Schemas.DownholeIntervalsSchema: {
                if (!params) {
                    return undefined;
                }

                viewId = toSuffixUid(objectId, ViewClass.Lines);
                const linesSnapshot = generateTileset3DLinesSnapshot(
                    viewId,
                    gooseObject as GeoscienceLineObject,
                    params,
                );
                return linesSnapshot;
            }
            case Schemas.DownholeCollectionSchema: {
                viewId = toSuffixUid(objectId, ViewClass.Points);

                const snapshotParams: PointSnapshotParams = {
                    objectId: gooseObject.object_id,
                    width: gooseObject.object.location.coordinates.width,
                    length: gooseObject.object.location.coordinates.length,
                    dataType: gooseObject.object.location.coordinates.data_type,
                    dataId: gooseObject.object.location.coordinates.data,
                    data: gooseObject.links.data,
                };

                const pointsSnapshot = generatePointsSnapshot(viewId, snapshotParams);

                return pointsSnapshot;
            }
            case Schemas.TriangleMeshSchema: {
                if (!params) {
                    return undefined;
                }
                viewId = toSuffixUid(objectId, ViewClass.Surface);

                const triangleMeshSnapshot = generateSurfaceSnapshot(
                    viewId,
                    gooseObject as GeoscienceMeshObject,
                    params,
                );
                return triangleMeshSnapshot;
            }
            default:
                return undefined;
        }
    }

    return {
        viewId,
        snapshot: viewSnapshot,
    };
}

export function createProjectTreeViewSnapshot(
    treeItem: TreeStructure,
    gooseObject: GetObjectResponse,
    tileset?: DownholeCollectionTileset,
): CreateViewSnapshot {
    let viewId = '';
    const snapshot = generateSnapshot();
    if (!snapshot) {
        console.error(`Unsupported schema: ${gooseObject.object.schema}`);
    }
    function generateSnapshot() {
        switch (treeItem.schema) {
            case Schemas.DownholeIntervalsSchema: {
                if (!tileset) return undefined;
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);
                return generateDownholeCollectionLinesSnapshot(
                    treeItem,
                    tileset,
                    gooseObject.object as DownholeCollectionType,
                );
            }
            case Schemas.PointsetSchema: {
                viewId = toSuffixUid(treeItem.treeId, ViewClass.Points);
                const snapshotParams: PointSnapshotParams = {
                    objectId: gooseObject.object_id,
                    width: gooseObject.object.location.coordinates.width,
                    length: gooseObject.object.location.coordinates.length,
                    dataType: gooseObject.object.location.coordinates.data_type,
                    dataId: gooseObject.object.location.coordinates.data,
                    data: gooseObject.links.data,
                };
                return generatePointsSnapshot(viewId, snapshotParams);
            }
            default:
                return undefined;
        }
    }

    return {
        viewId,
        snapshot,
    };
}

function generateSurfaceSnapshot(
    viewId: string,
    gooseObject: GeoscienceMeshObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot | undefined {
    const { object_id: objectId } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            wireframe: false,
            showFaces: true,
            color: MESH_DEFAULT_COLOR,
            color_back: MESH_DEFAULT_COLOR_BACK,
        }),
    };
}

function generatePointsSnapshot(
    viewId: string,
    gooseObject: PointSnapshotParams,
): UpdateSnapshot | undefined {
    const { objectId, width, length, dataType, dataId, data } = gooseObject;

    const dataObject = data.find(
        (dataDownloadUrl) =>
            dataDownloadUrl.id === dataId ||
            (dataDownloadUrl as NamedDataDownloadUrl).name === dataId,
    );
    if (!dataObject?.download_url) {
        return undefined;
    }

    const elementId = toSuffixUid(objectId, ElementClass.Points);
    const snapshot = {
        [elementId]: generateEntity(ElementClass.Points, {
            verticesUrl: {
                location: dataObject.download_url,
                shape: [length, width],
                dataType,
            },
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
        }),
    };
    return snapshot;
}

export function generateVisualizationServiceUrl(baseUrl: string, params: GetObjectByIdApiArg) {
    return `${baseUrl}/visualization/orgs/${params.orgId}/workspaces/${params.workspaceId}/geoscience-object/${params.objectId}`;
}

function generateTileset3DLinesSnapshot(
    viewId: string,
    gooseObject: GeoscienceLineObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot | undefined {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(object.attributes);
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Lines, {
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            width: LINE_DEFAULT_WIDTH,
            mode: LINE_DEFAULT_MODE,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}

function generateDownholeCollectionLinesSnapshot(
    treeItem: TreeStructure,
    tileset: DownholeCollectionTileset,
    gooseObject: DownholeCollectionType,
): UpdateSnapshot | undefined {
    const {
        schema: { classes },
        groups,
        root: { children },
    } = tileset;
    const intervalTable = Object.keys(classes).find(
        (intervalClass) => classes[intervalClass].name === treeItem.name,
    );
    const intervalIndex = groups.findIndex(({ class: className }) => className === intervalTable);
    const intervalToLoad = children[intervalIndex];
    if (!intervalToLoad) return undefined;
    const { content } = intervalToLoad;

    const elementId = toSuffixUid(treeItem.treeId, ElementClass.Tileset3D);
    const viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);

    const collectionObj = gooseObject.collections.find(
        (collection) => collection.name === treeItem.name,
    );
    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        collectionObj?.from_to?.attributes,
    );

    const snapshot = {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            id: elementId,
            url: content.uri,
        }),
        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            mode: LinesMode.Tubes,
            radius: TUBE_DEFAULT_RADIUS,
            width: LINE_DEFAULT_WIDTH,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };

    return snapshot;
}
