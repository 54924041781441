import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { Schemas } from 'src/visualization/constants';
import { SUPPORTED_SCHEMAS } from 'src/visualization/utils/supportedTypes';

export const extractSchema = (fullSchema: string) => {
    if (!fullSchema) {
        return '';
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase();
};

export function isSchemaViewable(fullSchema: string, flags: LDFlagSet) {
    const schema = extractSchema(fullSchema);
    const { evouiTriangleMeshRendering } = flags;
    if (evouiTriangleMeshRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.TriangleMeshSchema);
    }
    return SUPPORTED_SCHEMAS.includes(schema as Schemas);
}
