import { CameraTools } from '@local/webviz/dist/components/CameraTools';
import { useBaseXyz } from '@local/webviz/dist/context';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { useAppDispatch } from 'src/store/store';
import { clearSceneState, updateOverlays } from 'src/store/visualization/visualizationSlice';

import { CLEAR_SCENE } from './Toolbar.constants';
import { useStyles } from './Toolbar.styles';

export function Toolbar() {
    const { classes } = useStyles();
    const { clearScene } = useBaseXyz();
    const dispatch = useAppDispatch();

    const handleClear = () => {
        clearScene();
        dispatch(updateOverlays({ orientation: true, scalebar: true }));
        dispatch(clearSceneState({}));
    };

    return (
        <Grid container item className={classes.root} justifyContent="center">
            <Grid container item className={classes.tools} alignItems="center">
                <CameraTools labelsOn />
                <Divider orientation="vertical" className={classes.divider} />
                <Button
                    className={classes.resetButton}
                    color="secondary"
                    variant="contained"
                    onClick={handleClear}
                >
                    {CLEAR_SCENE}
                </Button>
            </Grid>
        </Grid>
    );
}
