import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import {
    ObjectTreeState,
    SelectionListState,
    SelectorTypeBoolean,
    SelectorTypeLoadedObjects,
    SelectorTypeLoadedObject,
    SelectorTypeObjectTree,
    SelectorTypeObjectTreeExpanded,
    SelectorTypeObjectTreeItem,
    SelectorTypeString,
    SelectorTypeStringOrUndefined,
    SelectorTypeStringArray,
    VisualizationState,
} from './visualizationSlice.types';

export const initialSelectionState: SelectionListState = {
    selection: [],
    shiftSelection: [],
};

const initialObjectTreeState: ObjectTreeState = {
    treeState: {},
    expanded: {},
};

export const initialState: VisualizationState = {
    projectTree: {
        selected: initialSelectionState,
    },
    scenePanel: {
        selected: initialSelectionState,
    },
    loadedObjects: {},
    overlays: { scalebar: true, orientation: true },
    objectTree: initialObjectTreeState,
};

const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjectsMap: SelectorTypeLoadedObjects = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string): SelectorTypeLoadedObject =>
    createSelector(
        visualizationState,
        (visualizationStateRoot) => visualizationStateRoot.loadedObjects[objectId],
    );

export const selectionListScenePanel: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.selection,
);
export const shiftSelectionScenePanel: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.shiftSelection,
);

export const lastSelectedScenePanel: SelectorTypeString = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.scenePanel.selected.lastSelected ?? '',
);

export const isSelectedInScenePanel = (objectId: string): SelectorTypeBoolean =>
    createSelector(selectionListScenePanel, (selectionList) => selectionList.includes(objectId));

export const selectionListProjectTree: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.selection,
);
export const shiftSelectionProjectTree: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.shiftSelection,
);

export const lastSelectedProjectTree: SelectorTypeString = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.projectTree.selected.lastSelected ?? '',
);

export const isSelectedInProjectTree = (objectId: string): SelectorTypeBoolean =>
    createSelector(selectionListProjectTree, (selectionList) => selectionList.includes(objectId));

export const getLoadedObjectSchemaByObjectId = (objectId: string): SelectorTypeStringOrUndefined =>
    createSelector(loadedObjectById(objectId), (treeItem) => treeItem?.schema);

export const getLoadedObjectNameByObjectId = (objectId: string): SelectorTypeStringOrUndefined =>
    createSelector(loadedObjectById(objectId), (treeItem) => treeItem?.name);

export const getObjectTree: SelectorTypeObjectTree = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.objectTree,
);
export const getObjectTreeExpanded: SelectorTypeObjectTreeExpanded = createSelector(
    getObjectTree,
    (objectTree) => objectTree.expanded,
);
export const getObjectTreeExpandedById = (treeId: string): SelectorTypeBoolean =>
    createSelector(getObjectTreeExpanded, (expanded) => expanded[treeId] ?? false);

export const getTreeItemById = (treeId: string): SelectorTypeObjectTreeItem =>
    createSelector(getObjectTree, (objectTree) => objectTree.treeState[treeId]);
