import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { Nullable, UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import {
    ArrayClass,
    FilterClass,
    MappingClass,
    SceneClass,
    toSuffixUid,
} from '@local/webviz/dist/xyz';

import { AttributeTypes } from 'src/visualization/constants';
import { AttributeSchema, AttributeType } from 'src/visualization/types';
import { SUPPORTED_ATTRIBUTE_TYPES } from 'src/visualization/utils/supportedTypes';

export const DEFAULT_GRADIENT = 'VIRIDIS';

export function generateAttributeListSnapshot(
    attributes?: AttributeSchema[],
): [string, UpdateSnapshot] {
    let attributesSnapshot = {};
    let firstColorData = '';

    if (attributes) {
        attributesSnapshot = attributes
            .filter((attr) =>
                SUPPORTED_ATTRIBUTE_TYPES.includes(attr.attribute_type as AttributeTypes),
            )
            .reduce(
                (accumulator: UpdateSnapshot, { values: { data }, attribute_type }) => ({
                    ...accumulator,
                    ...generateAttributesSnapshot(data, attribute_type),
                }),
                {},
            );

        const firstAttribute = attributes.find((attr) =>
            SUPPORTED_ATTRIBUTE_TYPES.includes(attr.attribute_type as AttributeTypes),
        );
        if (firstAttribute?.values?.data) {
            firstColorData = toSuffixUid(
                `attribute_${firstAttribute.values.data}`,
                UID_SUFFIXES.DATA,
            );
        }
    }
    return [firstColorData, attributesSnapshot];
}

export function generateAttributesSnapshot(
    attribute: Nullable<string>,
    attributeType: AttributeType,
): UpdateSnapshot {
    if (!attribute) {
        return {};
    }

    const attributeId = `attribute_${attribute}`;
    const dataId = toSuffixUid(attributeId, UID_SUFFIXES.DATA);
    const arrayId = toSuffixUid(attributeId, UID_SUFFIXES.ARRAY);
    const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
    const filterId = toSuffixUid(attributeId, UID_SUFFIXES.FILTER);
    const categoryMappingTitlesId = toSuffixUid(attributeId, UID_SUFFIXES.TITLES);
    const categoryMappingColorsId = toSuffixUid(attributeId, UID_SUFFIXES.COLORS);

    const arraySnapshot = {
        [arrayId]: generateEntity(ArrayClass.PropertyTable, {
            propertyId: attributeId,
            id: arrayId,
        }),
    };
    let colorSnapshot = {};
    if (attributeType === AttributeTypes.Scalar) {
        /** it's necessary to update visibility with true values as without it the color mapping filter woun't work
        and the length should be data_control_values + 1 (i.e 4 + 1 = 5) */
        colorSnapshot = {
            [mappingId]: generateEntity(MappingClass.Continuous, {
                gradient: DEFAULT_GRADIENT,
                data_control_values: [-Infinity, -Infinity, +Infinity, +Infinity],
                id: mappingId,
                visibility: [true, true, true, true, true],
            }),
        };
    } else if (attributeType === AttributeTypes.Category) {
        colorSnapshot = {
            [categoryMappingTitlesId]: generateEntity(ArrayClass.String, {
                id: categoryMappingTitlesId,
            }),
            [categoryMappingColorsId]: generateEntity(ArrayClass.Color, {
                id: categoryMappingColorsId,
            }),
            [mappingId]: generateEntity(MappingClass.Category, {
                categories: [categoryMappingColorsId, categoryMappingTitlesId],
                id: mappingId,
            }),
        };
    }

    const filterSnapshot = {
        [filterId]: generateEntity(FilterClass.Numeric, {
            id: filterId,
            min: -Infinity,
            max: +Infinity,
        }),
    };

    const dataSnapshot = {
        [dataId]: generateEntity(SceneClass.Data, {
            id: dataId,
            mapping: mappingId,
            array: arrayId,
            filter: filterId,
        }),
    };

    return {
        ...arraySnapshot,
        ...colorSnapshot,
        ...filterSnapshot,
        ...dataSnapshot,
    };
}
